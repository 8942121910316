.footer_main_div {
  background-color: black;
  padding: 50px;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;

}

.footer_logo {
  width: 10rem;
}
.footer_textleft {
  color: var(--main);
  font-family: N_SemiBold;
}
.footer_textleft:hover{
  text-decoration: underline
}
.footer_div_left {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: space-between;
  width: 90%;
}
.footer_div_right {
  color: white;
  width: 50%;
}

.footer_textright {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: fit-content;
}
.footer_text:hover{
    text-decoration: underline;
}

.footer_text {
  font-family: N_light;
  font-size: 1.2rem;
  cursor: pointer;

}

.footer_text_header {
  font-family: N_Bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.footer_textright_socials
{
    display: flex;
    gap: 2rem;
    margin-top: 3rem;
    flex-wrap: wrap;
}
.footer_textright_socials a
{
    color: var(--main);
}

.footer_textright_socials a:hover{
    text-decoration: underline;
}

@media screen and (max-width: 980px) {
    .footer_main_div{
        flex-direction: column;

    }
.footer_div_left, .footer_div_right
{
    width: 100%;
}
    }