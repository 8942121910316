.header_main_div {
  padding: 20px 50px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 2000;
  align-items: center;
}

.banner_div {
  cursor: pointer;
}

.header_menu_div {
  background-color: var(--main);
  z-index: 30000;
  height: 100vh;
  width: 50vw;
  display: flex;
  flex-direction: column;
  padding: 50px;
  float: right;
  transition: all 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
  transition-delay: 100ms;
  animation: slideIn 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
}

.header_menu_div_main {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 40000;
  right: 0;
  top: 0px;
  background: black;
  transition: all 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
  transition-delay: 100ms;
  animation: slideIn2 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
}

.header_menu_div_main_close {
  position: fixed;
  height: 0vh;
  width: 0vw;
  z-index: -1;
  right: 0;
  top: 0px;
  background: black;
  transition: all 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
  transition-delay: 100ms;
  animation: slideIn2 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
  opacity: 0;
}

.header_menu_div_close {
  background-color: var(--main);
  z-index: -1;
  height: 0vh;
  width: 0vw;
  display: flex;
  flex-direction: column;
  padding: 50px;
  float: right;
  transition: all 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
  transition-delay: 100ms;
  animation: slideIn 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
  opacity: 0;
}

.close_banner {
  cursor: pointer;
  width: 3rem;
  align-self: flex-end;
}
.header_menu_content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.header_menu_text_content {
  cursor: pointer;
  font-family: N_SemiBold;
  font-size: 1.2rem;
  width: fit-content;
}

.header_menu_text_content:hover {
  text-decoration: underline;
}

@keyframes slideIn {
  0% {
    width: 0%;
    opacity: 0;
  }

  100% {
    width: 50%;
    opacity: 1;
  }
}

@keyframes slideIn2 {
  0% {
    width: 0%;
    opacity: 0;
  }

  100% {
    width: 100%;
    opacity: 1;
  }
}
.hide_bar {
  display: none;
}

@media screen and (max-width: 568px) {
  .logo_header {
    width: 8rem !important;
  }
  .banner_div {
    width: 4rem !important;
  }
  .header_menu_div,
  .header_menu_div_main {
    width: 100vw !important;
  }
  .header_menu_div_main_close,
  .header_menu_div_close {
    width: 0vw !important;
    padding: 20px !important;
  }
}
