@import url("https://rsms.me/inter/inter.css");

.landing_main_div {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: linear-gradient(180deg, #e6eaf5 0%, #f6f6f6 80%);
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  font-family: "Inter var", sans-serif;
  cursor: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMwLjUgMTZDMzAuNSAyNC4wMDgxIDI0LjAwODEgMzAuNSAxNiAzMC41QzcuOTkxODcgMzAuNSAxLjUgMjQuMDA4MSAxLjUgMTZDMS41IDcuOTkxODcgNy45OTE4NyAxLjUgMTYgMS41QzI0LjAwODEgMS41IDMwLjUgNy45OTE4NyAzMC41IDE2WiIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNNiA5QzcuNjU2ODUgOSA5IDcuNjU2ODUgOSA2QzkgNC4zNDMxNSA3LjY1Njg1IDMgNiAzQzQuMzQzMTUgMyAzIDQuMzQzMTUgMyA2QzMgNy42NTY4NSA0LjM0MzE1IDkgNiA5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="),
    auto;
}

a {
  padding-right: 20px;
  cursor: pointer;
  pointer-events: all;
  color: black;
  text-decoration: none; /* no underline */
  font-family: N_Reg;
}

@media screen and (max-width: 568px) {
  .full,
  .overlay_div {
    visibility: hidden;
    display: none;
  }
  .landing_main,
  .header_main_div {
    padding: 20px 20px !important;
  }

  .margin_div_landing {
    height: 70px !important;
  }
  .button_landing_divv{
    margin-bottom: 50px;
  }
}

.button_landing {
  border: 3px solid var(--main) !important;
}
.button_landing:hover {
  border: 3px solid black !important;
}

.overlay_div a:hover{
text-decoration: underline;
}
