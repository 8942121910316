.addon_main_box
{
    border: 3px solid black;
    min-height: 12rem;

    padding: 30px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}
.addon_left_div
{
    width: 80%;
}
.addon_title{
    font-size: 1.2rem;
    font-family: N_SemiBold;
    margin-bottom: 1rem;
}
.addon_info{
    font-family: N_Light;
}
.addon_right_div
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.addon_price
{
    margin-top: 1.5rem;
    text-align: center;
}
.addon_price span{
    opacity: 0.6;
    font-family: N_Light;
    font-size: 0.9rem;
}
.addon_price_info{
    margin-top: 0.5rem;
    font-family: N_SemiBold;
    font-size: 1.5rem;
}