.notFound {
    display: flex;
    background-size: cover;
    width: 100%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 5rem;
}
.notFound_img {
  
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    margin: auto;
    margin-top: 10rem;
    width: 50%;
    height: auto;
    margin-bottom: 10rem;
}

@media screen and (max-width: 550px) {
    .notFound_img{
        width: 90%;
    }
}