.privacy_main_div
{
    padding: 0px 50px;
}

@media screen and (max-width: 550px) {
    .privacy_main_div
    {
        padding: 0px 20px;
    }
    
}