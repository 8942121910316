.quote_main_div {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}
.quote_left_div {
  width: 50%;
  padding: 30px;
}
.package_box_main {
  border: 3px solid black;
  margin-top: 2rem;
}
.quote_header {
  font-size: 2.8rem;
  font-family: N_Bold;
}
.quote_header2 {
  font-size: 2rem;
  font-family: N_Bold;
  margin-bottom: 2rem;
}

.package_box_upper {
  display: flex;
  justify-content: space-between;
  border-bottom: 3px solid black;
  align-items: center;
}
.package_left_div {
  width: 80%;
  padding: 20px;
}

.package_right_div {
  padding: 20px;
}

.package_header_div {
  font-size: 1.1rem;
  font-family: N_SemiBold;
  margin-bottom: 0.5rem;
}
.package_Subheader_div {
  font-size: 1.1rem;
}
.package_box_second {
  border-bottom: 3px solid black;
}
.package_box_second,
.package_box_third {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.package_desc_div {
  width: 50%;
  padding: 20px 20px;
}
.package_desc_div:first-child {
  border-right: 3px solid black;
}
.package_dec_info {
  font-family: N_Light;
  font-size: 1rem;
  line-height: 2rem;
}
.package_box_third {
  padding: 30px 20px;
  gap: 3rem;
}

.package_summary_header {
  font-size: 1.5rem;
  font-family: N_SemiBold;
}
.package_summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  width: 50%;
}
.package_summary_info {
  font-size: 0.9rem;
  opacity: 0.6;
}

.quote_left_addons {
  margin-top: 2.5rem;
}

.quote_right_div {
  width: 45%;
  padding: 30px;
}

.quick_quote_div {
  border: 3px solid black;
  padding: 20px;
  margin-top: 2rem;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.quick_quote_div_total {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.quick_quote_div_total_left,
.quick_quote_div_total_right {
  font-family: N_SemiBold;
  font-size: 1.1rem;
}
.addons_div_quote_addons {
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  margin: 1rem 0 2rem 1.5rem;
  gap: 0.5rem;
}

.quote_right_div_secondry {
  margin-top: 2.5rem;
}
.quick_quote_div_total_left_sub {
  font-size: 1.1rem;
}

.button_main {
  border: 3px solid var(--main);
  background-color: #d6ff00;
  color: black;
  cursor: pointer;
  padding: 1.5rem 3rem;
  font-size: 1.3rem;
  font-family: N_SemiBold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button_main:hover {
  border: 3px solid black;
}
.quote_right_div_bottom {
  margin-top: 2.5rem;
}
.quote_right_div_top_mobile{
  display: none;
}

@media screen and (max-width: 980px) {
  .quote_main_div {
    flex-direction: column;
  }

  .quote_left_div,
  .quote_right_div {
    flex-grow: 1;
    width: 100%;
  }
  .input_main {
    width: 100%;
  }
  .quote_right_div_top{
    display: none;
  }
  .quote_right_div_top_mobile .quote_header_mobile{
      width: 90%;
      margin: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 1rem ;
  }
  .quote_header_mobile_text{
    font-size: 1.5rem;
    font-family: N_SemiBold;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .arrow_img_mobile{
    transform: rotate(0deg);
    animation: anti_rotation 0.5s alternate linear;
  }



  .quote_right_div_top_mobile{
    display: block;
    position: sticky;
    bottom: 0;
    background-color: #e6eaf5;
    border-top: 3px solid black;
    border-bottom: 3px solid black;
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    cursor: pointer;
  }
  .next_button_parent{
    width: 90%;
    margin: auto;
    z-index: 2;
  }
  .next_button a{
    font-family: N_SemiBold;
  }
  .next_button{
    background-color: #D6FF00;
    color: black;
    
    border: none;
    width: 100%;
    margin: auto;
    padding: 1rem 0;
    font-size: 1.3rem;
    margin-bottom: 1rem;
    z-index: 2;
    
  }
  /* .quote_right_div_top{
    position: fixed !important;
    bottom: 0;
    left: 0;
    background-color: white;
    color: white;
    padding: 1rem;
    width: 100%;
    border: 3px solid black;
    
  } */
  .quick_quote_div{
    border: none !important;
  }
  .quick_quote_div, .addons_div_quote_addons{
    /* margin-bottom: 0 !important;
    margin-top: 0 !important; */
  }
  .quote_header{
    text-align: center;
  }
}

@media screen and (max-width: 550px) {
  .quote_header {
    font-size: 2rem !important;
  }
  .package_left_div
  {
    padding-left: 20px;
  }
  .quote_header2 {
    font-size: 1.5rem;
  }
  .package_header_div,
  .package_Subheader_div {
    font-size: 1rem;
  }
  .quote_main_div {
    padding: 0;
  }
  .form_quota_radio_main {
    width: 100% !important;
  }
  .package_desc_div {
    padding: 20px 20px !important;
    width: 100%;
  }
  .package_desc_div:first-child {
    border-right: none !important;
    border-bottom: 3px solid black;
  }
  .package_box_second,
  .package_box_third {
    flex-direction: column;
  }
  .package_box_third {
    gap: 1.5rem;
    padding: 20px 20px;
  }
  .package_summary {
    width: 100%;
  }

  .addon_main_box
  {
    flex-direction: column;
    gap: 2rem;
    padding: 20px 20px !important;

  }
  .addon_left_div

  {
    width: 100% !important;
  }
  .addon_right_div
  {
    width: 100% !important;
    flex-direction: row-reverse !important;
    align-items: center !important;
    gap: 2rem;
  }
  .addon_price
  {
    margin: 0 !important;
  }
  .addon_title ,.addon_info{
    font-size: 1rem !important;
  }
  .addon_price_info,.package_summary_header{
    font-size: 1.2rem !important; 
  }
  .quick_quote_div_total_left, .quick_quote_div_total_right,.quick_quote_div_total_left_sub ,.quick_quote_div_total_right_sub {
    font-size: 1rem !important
}
.button_main{
  font-size: 1.2rem !important; 
}
.quick_quote_div{
  
  padding: 20px 20px !important;

}
.addons_div_quote_addons
{
  margin: 1rem 0 2rem 0.5rem;
}
}
