.contactUs_Main {
  margin: auto;
  padding: 2rem 50px;
}

.contactUs_top {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.contactUs_top_left {
  width: 50%;
}
.contactUs_top_right {
  width: 50%;
}
.contactUs_top_left_top {
  position: relative;
  margin-left: 10px;
}
.contactUs_top_left_top_text1 {
  position: absolute;
  z-index: 2;
  font-size: 10rem;
  font-family: N_Bold;
  line-height: 1;
  top: 7px;
  left: -10px;
}
.contactUs_top_left_top_text2 {
  color: transparent;
  -webkit-text-stroke: 3px var(--main);
  font-size: 10rem;
  font-family: N_Bold;
  line-height: 1;
}
.button_main:disabled {
  background-color: transparent !important;
  cursor: auto;
  border: 3px solid black;
}
.input_field {
  resize: none;
  width: 100%;
}
.input_field::placeholder {
  color: grey;
}
.form_main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 3rem;
}
.Form_middle {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.contactUs_top_bot_right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  gap: 1rem;
}
.contactUs_top_bot_right div,
.contactUs_top_bot_right a {
  font-family: N_Bold;
  font-size: 1.3rem;
}
.contactUs_top_bot {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;
}
.contactUs_top_bot_left {
  width: 50%;
}
@media (width < 1200px) {
  .contactUs_top_left_top_text1 {
    font-size: 7rem;
  }
  .contactUs_top_left_top_text2 {
    font-size: 7rem;
  }
}
@media (width >= 860px) {
  .contactUs_top_left_bottom {
    display: none;
  }
}
@media (width < 860px) {
  .contactUs_top {
    flex-direction: column;
  }
  .breakline {
    display: none;
  }
  .contactUs_top_left {
    width: 100%;
  }
  .contactUs_top_right {
    width: 100%;
  }
  .contactUs_top_bot_left {
    display: none;
  }
  .contactUs_top_bot_right {
    width: 100%;
    flex-wrap: wrap;
  }
}
@media (width < 630px) {
  .contactUs_top_left_top_text1 {
    font-size: 6rem;
  }
  .contactUs_top_left_top_text2 {
    font-size: 6rem;
  }
}
@media (width < 570px) {
  .contactUs_Main {
    padding: 2rem 20px;
  }
}
@media (width < 480px) {
  .contactUs_top_left_top_text1 {
    font-size: 5rem;
  }
  .contactUs_top_left_top_text2 {
    font-size: 5rem;
  }
  .breakline {
    display: block;
  }
}

.FAQ {
  margin-top: 4rem;
}
@media (width < 860px) {
  .FAQ {
    display: none;
  }
}
.FAQ_title {
  position: relative;
}
.FAQ_title_text1 {
  position: absolute;
  top: 2px;
  left: -7px;
  font-size: 3.5rem;
  font-family: N_Bold;
}
.FAQ_title_text2 {
  font-size: 3.5rem;
  font-family: N_Bold;
  color: transparent;
  -webkit-text-stroke: 2px var(--main);
}
.FAQ_main {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  gap: 3rem;
}
.FAQ_main_left {
  width: 20%;
}
.FAQ_main_left_title {
  font-family: N_Bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.FAQ_main_left_content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
}
.FAQ_main_left_content_item {
  padding: 1rem;
}
.selectedItem {
  background-color: var(--main);
  border-bottom: 2px solid black;
  margin-bottom: -2px;
}
.FAQ_horizontal_line {
  width: 2px;
  background-color: black;
}
.FAQ_main_right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
.faq_subcontent_main {
  border-bottom: 2px solid black;
  padding: 1.5rem 1rem;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.faq_subcontent_main_body {
  display: block;
  margin-top: 1rem;
  transition: all 2s ease-in-out;
  &:not(.selectedSubContent_body) {
    display: none;
    transition: all 2s ease-in-out;
  }
}
.selectedSubContent {
  background-color: var(--main);
}
.selectedSubContent_title {
  font-family: N_SemiBold;
}
.arrow_img {
  width: 20px;
  transform: rotate(180deg);
}
.selected_Img {
  transform: rotate(0deg);
}
.FAQ_mobile {
  margin-top: 5rem;
}

.FAQ_mobile_title {
  font-family: N_Bold;
  font-size: 2rem;
  margin-bottom: 2rem;
}
.FAQ_mobile_main {
  margin-top: 2rem;
}
.FAQ_mobile_main_title {
  font-family: N_SemiBold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.FAQ_mobile_main_subcontent {
  border-bottom: 2px solid black;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.FAQ_mobile_main_subcontent_body {
  display: block;
  margin-top: 1rem;
  transition: all 2s ease-in-out;
  &:not(.selected_FAQ_mobile_main_subcontent_body) {
    display: none;
    transition: all 2s ease-in-out;
  }
}
.selected_FAQ_mobile_main_subcontent {
  background-color: var(--main);
}
.selected_FAQ_mobile_main_subcontent_title {
  font-family: N_SemiBold;
}
.mobile_arrow_img {
  width: 20px;
  transform: rotate(180deg);
}
.selected_mobile_img {
  transform: rotate(0deg);
}

@media (width >= 860px) {
  .FAQ_mobile {
    display: none;
  }
}
