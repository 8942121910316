.aboutUs_main_div {
  padding: 50px;
  padding-top: 20px;

}
.about_main_img {
  width: 100%;
  height: 70vh;
  object-fit: cover;
}
.about_header {
  width: fit-content;
  z-index: 20000;
  font-family: N_Bold;
  text-shadow: 3px 3px #d6ff00;
}
.aboutUs_header3_div {
  display: none;
}

.about_main_img2 {
  display: none;
}

.aboutUs_header {
  position: absolute;
  margin-left: 2rem;
  top: 25%;
}
.aboutUs_header1 {
  position: absolute;
  z-index: 2;
  font-size: 10rem;
  font-family: N_Bold;
  top: 7px;
  left: 0px;
}
.aboutUs_header2 {
  font-family: N_Bold;
  font-size: 10rem;
  left: 100px;
  position: relative;
  color: transparent;
  font-family: N_Bold;
  -webkit-text-stroke: 3.5px #d6ff00;
  left: 10px;
  top: 10px;
}

.aboutUs_text_div {
  margin-top: 4rem;
}
.about_header_back {
  position: relative;
  color: transparent;
  font-family: N_Bold;
  -webkit-text-stroke: 2px #d6ff00;
  left: 120px;
  top: 2px;
  font-size: 2.8rem;
}

.aboutUs_content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
}

.about_content_text {
  font-size: 1.1rem;
  width: 48%;
}

.aboutUs_content2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  align-items: stretch;
  margin-top: 2.5rem;
}
.about_content_text_box {
  padding: 30px;
  font-size: 1.2rem;
  width: 48%;
  min-height: 14rem;
  font-family: N_Reg;
}
.about_content_text_box_header {
  font-size: 1.7rem;
  font-family: N_Bold;
  margin-bottom: 1rem;
}

@media screen and (max-width: 980px) {
  .aboutUs_content,
  .aboutUs_content2 {
    flex-direction: column;
    gap: 1rem;
  }
  .about_content_text,
  .about_content_text_box {
    width: 100%;
  }
  .aboutUs_header1,
  .aboutUs_header2 {
    font-size: 8rem !important;
  }
}

@media screen and (max-width: 768px) {

  .aboutUs_header3 {
    background: rgba(255, 255, 255, 0.07);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2.9px);
    -webkit-backdrop-filter: blur(2.9px);
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    margin: auto;
    margin-top: 10rem;
    width: 90%;
    height: auto;
    margin-bottom: 10rem;
  }

  .aboutUs_header1,
  .aboutUs_header2 {
    display: none;
  }
  .aboutUs_header3_div {
    display: flex;
    background-image: url("../../Assets/about3.png");
    background-size: cover;
    width: 100%;
  }
 
  .hide {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .aboutUs_main_div {
    padding: 20px;
  }

  .aboutUs_header1,
  .aboutUs_header2 {
    font-size: 2rem !important;
  }
}
