.form_quota_main_div {
  width: 100%;
}
.form_quota_form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.input_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
}
.input_main {
  flex-grow: 1;
  border: 3px solid black;
  padding: 20px 20px;
  font-family: N_Reg;
  color: black;
  background-color: transparent;
  font-size: 1.1rem;
}

.form_quota_radio_div {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
margin-bottom: 2rem};
.radio_title {
  font-family: N_Reg;
  font-size: 1.2rem;
}
.radio_flex {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.form_quota_radio_comp
{
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.form_quota_radio_main{
  width: 45%;
}

.message
{
  color:black;
  font-family: N_SemiItalic;

}