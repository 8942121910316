body {
  margin: 0;
  font-family: N_Reg;
}
html{scroll-behavior:smooth}
* {
  box-sizing: border-box;
  font-family: N_Reg;
  user-select: none;
}

:root {
  --main: #d6ff00;
}
html,
body,
#root {
  margin: 0;
  padding: 0;
  background: #e6eaf5;
  background-repeat: no-repeat;

}
@font-face {
  font-family: N_Reg;
  src: url("../src/Assets/fonts/NoirPro-Regular.otf");
}
@font-face {
  font-family: N_Bold;
  src: url("../src/Assets/fonts/NoirPro-Bold.otf");
}
@font-face {
  font-family: N_Light;
  src: url("../src/Assets/fonts/NoirPro-Light.otf");
}
@font-face {
  font-family: N_SemiBold;
  src: url("../src/Assets/fonts/NoirPro-SemiBold.otf");
}

@font-face {
  font-family: N_SemiItalic;
  src: url("../src/Assets/fonts/NoirPro-SemiBoldItalic.otf");
}
